import {
  createProductionInstance,
  validateCloning,
} from '@/app/api/applications';
import {
  EnvironmentType,
  Instance,
  InstanceExtended,
} from '@/app/api/types/instances';
import { Badge } from '@/app/components/badge';
import { Button } from '@/app/components/ui/Button';
import { Dialog } from '@/app/components/ui/Dialog';
import { Input } from '@/app/components/ui/Input';
import { RadioGroup } from '@/app/components/ui/RadioGroup';
import { useDeprecatedForm } from '@/app/hooks/useControlledForm';
import { HTTPError } from '@/app/utils/errors';
import { stripProtocol } from '@/app/utils/url';
import { useAuth } from '@clerk/nextjs';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';
import React, { useId, useRef, useState } from 'react';
import { z } from 'zod';
// TODO: This is imported from the pages router, we need to decide
//       what to do with this long term.
import { useTLDParser } from '@/hooks/useTLDParser';
// TODO: Remove this when not needed
import { Instance as PagesBaseInstance } from '@/dapi/instances/types';

export function CreateProductionInstanceDialog({
  trigger,
  instances,
  invalidateApplication,
  showPaymentModal,
  track,
}: {
  trigger: React.ReactNode;
  instances: Array<Instance | PagesBaseInstance>;
  invalidateApplication: () => Promise<unknown>;
  showPaymentModal: ({ features, exceptionForUpgradeModal }) => void;
  track: (event: string, properties?: any) => void;
}) {
  const formId = useId();
  const formRef = useRef<HTMLFormElement>(null);
  const { getToken } = useAuth();
  const { applicationId } = useParams<{ applicationId: string }>()!;
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<'select-method' | 'configure'>(
    'select-method',
  );
  const [isValidating, setIsValidating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const { parse } = useTLDParser();

  const nonProductionInstances = React.useMemo(
    () =>
      instances
        ? instances
            .filter(i => i.environment_type !== EnvironmentType.Production)
            .sort(devInstancesFirst)
        : [],
    [instances],
  );

  const cloneInstanceIdDefaultValue = nonProductionInstances[0]?.id;

  const defaultValues = {
    clone_instance_id: cloneInstanceIdDefaultValue,
    home_url: '',
    is_secondary: false,
  };
  const { Field, reset, getFieldValue, handleSubmit, setFieldMeta, Subscribe } =
    useDeprecatedForm(
      formRef,
      {
        defaultValues,
        onSubmit: async ({ value }) => {
          const { clone_instance_id, home_url, is_secondary } = value;

          const cloneInstanceId =
            clone_instance_id === 'default' ? null : clone_instance_id;
          const homeUrl = `https://${stripProtocol(home_url)}`;

          let newInstance: InstanceExtended | undefined;
          try {
            setIsSubmitting(true);
            newInstance = await createProductionInstance(
              applicationId,
              {
                clone_instance_id: cloneInstanceId,
                home_url: homeUrl,
                is_secondary,
              },
              { getToken },
            );
          } catch (error: unknown) {
            if (!(error instanceof HTTPError)) {
              throw error;
            }

            for (const fieldError of error.fieldErrors) {
              setFieldMeta(
                fieldError.meta.param_name as
                  | 'clone_instance_id'
                  | 'home_url'
                  | 'is_secondary',
                oldMeta => ({
                  ...oldMeta,
                  errorMap: {
                    onSubmit: fieldError?.long_message ?? fieldError.message,
                  },
                }),
              );
            }

            setIsSubmitting(false);
            return;
          }

          await invalidateApplication();
          router.push(`/apps/${applicationId}/instances/${newInstance.id}`);
          setIsSubmitting(false);
          reset();
        },
      },
      false,
    );

  const handleClose = () => {
    setStep('select-method');
    reset();
    setOpen(false);
  };

  const nextStep = async () => {
    if (getFieldValue('clone_instance_id') === 'default') {
      setStep('configure');
    } else {
      setIsValidating(true);

      try {
        await validateCloning(
          applicationId,
          getFieldValue('clone_instance_id'),
          { getToken },
        );

        setStep('configure');
      } catch (error) {
        if (error instanceof HTTPError) {
          if (error.code === 402) {
            handleClose();

            const unsupportedFeatures = error.errorMeta
              .filter(err => err?.unsupported_features)
              .flatMap(err => err.unsupported_features);

            showPaymentModal({
              features: unsupportedFeatures,
              exceptionForUpgradeModal: true,
            });

            return;
          }
        }

        throw error;
      } finally {
        setIsValidating(false);
      }
    }
  };

  return (
    <Dialog.Root
      trigger={trigger}
      open={open}
      onOpenChange={newOpen => {
        if (newOpen) {
          setOpen(newOpen);
        } else {
          handleClose();
        }
      }}
    >
      <Dialog.Header title='Create production instance' />
      <Dialog.Content>
        <form
          ref={formRef}
          id={formId}
          onSubmit={event => {
            event.preventDefault();
            event.stopPropagation();
            void handleSubmit();
          }}
        >
          {step === 'select-method' ? (
            <Dialog.Section>
              <Field name='clone_instance_id'>
                {field => (
                  <RadioGroup
                    name={field.name}
                    value={field.state.value}
                    items={[
                      ...nonProductionInstances.map(i => {
                        return {
                          label: (
                            <>
                              Clone {i.environment_type} instance{' '}
                              <Badge intent='primary'>Easiest</Badge>
                            </>
                          ),
                          description: `Authentication and theme settings will be copied from the ${i.environment_type} instance. Usage of premium features will require a plan upgrade.`,
                          value: i.id,
                        };
                      }),
                      {
                        label: 'Create default instance',
                        description:
                          'Use the default authentication and theme settings.',
                        value: 'default',
                      },
                    ]}
                    onValueChange={value => {
                      field.form.setFieldValue('clone_instance_id', value);
                    }}
                  />
                )}
              </Field>
            </Dialog.Section>
          ) : (
            <Dialog.Section>
              <Dialog.Notice
                intent='neutral'
                icon='book'
                className='rounded-[0.4375rem] !from-gray-200 !to-gray-200'
              >
                Learn more about{' '}
                <Link
                  href='https://clerk.com/docs/deployments/overview'
                  target='_blank'
                  className='font-book text-purple'
                >
                  deploying to production
                </Link>
              </Dialog.Notice>
              <Field
                name='home_url'
                validators={{
                  onSubmit: z
                    .string()
                    // Validate x.x - rely on BE for the rest
                    .regex(/.+\..+/, {
                      message: 'Enter a valid application domain',
                    }),
                }}
                children={field => (
                  <Input
                    label='Application domain'
                    description='Enter the full application domain where your application lives. Include the subdomain, if applicable.'
                    prefix='https://'
                    placeholder='e.g. example.com'
                    autoFocus
                    value={field.state.value}
                    onChange={e => field.handleChange(e.target.value)}
                    errors={field.state.meta.errors}
                  />
                )}
              />
              <Subscribe selector={state => state.values.home_url}>
                {home_url => {
                  const tldResult = parse(home_url);

                  if (!tldResult.subdomain) {
                    return null;
                  }

                  return (
                    <div className='rounded-lg bg-gray-200 p-4'>
                      <p className='mb-4 text-[0.875rem] font-normal leading-[1.125rem]'>
                        Is this the primary application for{' '}
                        <strong>{tldResult.domain}</strong> or a secondary
                        application?
                      </p>
                      <Field name='is_secondary'>
                        {field => (
                          <RadioGroup
                            name={field.name}
                            value={field.state.value ? 'true' : 'false'}
                            items={[
                              {
                                label: 'Primary application',
                                value: 'false',
                                children: (
                                  <ul className='ml-4 list-disc space-y-1 text-sm text-secondary'>
                                    <li>
                                      Clerk’s API will be hosted on{' '}
                                      <span className='font-semibold text-legacyGray-900'>
                                        clerk.{tldResult.domain}
                                      </span>
                                    </li>
                                    <li>
                                      Verification emails will be sent from @
                                      <span className='font-semibold text-legacyGray-900'>
                                        {tldResult.domain}
                                      </span>
                                    </li>
                                  </ul>
                                ),
                              },
                              {
                                label: 'Secondary application',
                                value: 'true',
                                children: (
                                  <ul className='ml-4 list-disc space-y-1 text-sm text-secondary'>
                                    <li>
                                      Clerk’s API will be hosted on{' '}
                                      <span className='font-semibold text-legacyGray-900'>
                                        clerk.{tldResult.hostname}
                                      </span>
                                    </li>
                                    <li>
                                      Verification emails will be sent from @
                                      <span className='font-semibold text-legacyGray-900'>
                                        {tldResult.hostname}
                                      </span>
                                    </li>
                                  </ul>
                                ),
                              },
                            ]}
                            onValueChange={value => {
                              field.form.setFieldValue(
                                'is_secondary',
                                value === 'true' ? true : false,
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  );
                }}
              </Subscribe>
            </Dialog.Section>
          )}
        </form>
      </Dialog.Content>
      <Dialog.Footer>
        {step === 'select-method' ? (
          <Button
            data-analytics='create-production-instance'
            intent='primary'
            onClick={nextStep}
            loading={isValidating}
          >
            Continue
          </Button>
        ) : (
          <>
            <Button
              intent='ghost'
              onClick={() => {
                setStep('select-method');
              }}
            >
              Back
            </Button>
            <Button
              type='submit'
              intent='primary'
              form={formId}
              loading={isSubmitting}
              onClick={() => {
                track(
                  'Dashboard_Create Production Instance Modal_Create Instance Button Clicked',
                  {
                    location: 'Instance Modal',
                    surface: 'Dashboard',
                  },
                );
              }}
            >
              Create Instance
            </Button>
          </>
        )}
      </Dialog.Footer>
    </Dialog.Root>
  );
}

const envTypeOrder = {
  [EnvironmentType.Development]: 0,
  [EnvironmentType.Staging]: 1,
  [EnvironmentType.Production]: 2,
};

type InstanceItem = Partial<InstanceExtended | PagesBaseInstance>;

const devInstancesFirst = (a: InstanceItem, b: InstanceItem) => {
  // These guards are to keep TS happy
  if (a.environment_type === undefined) {
    return 1;
  }

  if (b.environment_type === undefined) {
    return -1;
  }

  return envTypeOrder[a.environment_type] - envTypeOrder[b.environment_type];
};
